import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ConfirmLotStateModel } from '@store/mobile/confirm-lot/confirm-lot.model';
import {
  ConfirmLot,
  ResetConfirmedLots,
  ResetScannedLots,
  ScanLotId,
} from '@store/mobile/confirm-lot/confirm-lot.actions';

@State<ConfirmLotStateModel>({
  name: 'confirmLot',
  defaults: {
    confirmedLots: [],
    scannedLotIds: [],
  },
})
@Injectable()
export class ConfirmLotState {
  @Action(ConfirmLot)
  public confirmLot(ctx: StateContext<ConfirmLotStateModel>, confirmedLots: any): void {
    ctx.patchState({ ...confirmedLots });
  }

  @Action(ScanLotId)
  public setCurrentScannedLot(ctx: StateContext<ConfirmLotStateModel>, { currentScannedLot }: ScanLotId): void {
    const state = ctx.getState();
    let scannedLotIds = state.scannedLotIds;

    if (!scannedLotIds.includes(currentScannedLot)) {
      scannedLotIds = [...scannedLotIds, currentScannedLot];

      ctx.patchState({
        scannedLotIds,
      });
    }
  }

  @Action(ResetConfirmedLots)
  public reset(ctx: StateContext<Partial<ConfirmLotStateModel>>): void {
    ctx.patchState({
      confirmedLots: [],
    });
  }

  @Action(ResetScannedLots)
  public resetScannedLots(ctx: StateContext<Partial<ConfirmLotStateModel>>): void {
    ctx.patchState({
      scannedLotIds: [],
    });
  }
}
